<script setup lang="ts">
import { usePlayerStore } from '~/stores/usePlayerStore'

const playerStore = usePlayerStore()

const currentTime = computed(() => formatTime(playerStore.currentTime))
const duration = computed(() => formatTime(playerStore.duration))
const progress = ref([0])

const handleChange = (value: number[]) => {
  playerStore.seek(value[0])
}

watch(
  () => playerStore.currentTime,
  () => {
    progress.value = [playerStore.currentTime]
  }
)
</script>

<template>
  <div v-if="playerStore.duration" class="flex space-x-2 justify-center">
    <span class="text-sm text-gray-500 min-w-12 text-right">{{ currentTime }}</span>
    <PlayerProgressSlider
      v-model="progress"
      :disabled="!!playerStore.preRoll"
      :default-value="[0]"
      :max="playerStore.duration"
      :step="1"
      class="group"
      @value-commit="handleChange"
    />
    <span class="text-sm text-gray-500 w-16">{{ duration }}</span>
  </div>
</template>

<style scoped></style>
