<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import { usePlayerStore } from '~/stores/usePlayerStore'
import { Loader2 } from 'lucide-vue-next'
import { useToast } from '@/components/ui/toast/use-toast'

const { toast } = useToast()

const playerStore = usePlayerStore()

const reasons = [
  { title: 'DMCA violation', value: 'dmca_violation' },
  { title: 'Sexual content', value: 'sexual_content' },
  { title: 'Legal issue', value: 'legal_issue' },
  { title: 'Promotes terrorism', value: 'promotes_terrorism' },
  { title: 'Spam or misleading', value: 'spam_misleading' },
  { title: 'Violent or repulsive content', value: 'violent_repulsive_content' },
]

const isLoading = ref(false)
const open = ref(false)
const selectedReason = ref('')

const handleSubmit = async () => {
  if (!playerStore.media?.id) return

  if (!selectedReason.value) {
    toast({
      title: 'Validation error',
      description: 'Please select a valid reason to proceed with the report.',
      variant: 'destructive',
    })
  }

  isLoading.value = true

  const type = playerStore.isStation ? 'stations' : 'podcasts'

  try {
    await $fetch(`/api/${type}/${playerStore.media?.podcastId || playerStore.media.id}/report`, {
      method: 'POST',
      body: {
        reason: selectedReason.value,
      },
    })

    open.value = false
    selectedReason.value = ''

    toast({
      title: 'Report Submitted',
      description: 'Your report has been successfully sent. Thank you for your feedback.',
    })
  } catch (e) {
    console.log(e)

    toast({
      description: 'An error occurred while submitting your report. Please try again later.',
      variant: 'destructive',
    })
  }

  isLoading.value = false
}
</script>

<template>
  <Dialog v-model:open="open">
    <DialogTrigger as-child>
      <Button
        variant="outline"
        size="icon"
        class="rounded-full border-0 size-11 bg-gray-100 hover:bg-gray-200 active:bg-gray-300"
        @click="() => (open = true)"
      >
        <Icon size="24" name="solar:flag-outline" />
      </Button>
    </DialogTrigger>
    <DialogContent class="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Report content</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        Please select the appropriate reason for reporting this content. Your feedback will help us maintain community
        standards.
      </DialogDescription>

      <RadioGroup v-model="selectedReason" class="flex flex-col gap-6 py-4">
        <div v-for="reason in reasons" :key="reason.value" class="flex items-center gap-2">
          <RadioGroupItem :id="reason.value" :value="reason.value" />
          <Label :for="reason.value"> {{ reason.title }}</Label>
        </div>
      </RadioGroup>

      <DialogFooter>
        <DialogClose as-child>
          <Button type="button" variant="ghost" @click="() => (open = false)"> Cancel</Button>
        </DialogClose>

        <Button :disabled="isLoading" type="submit" @click="handleSubmit">
          <Loader2 v-if="isLoading" class="size-4 mr-2 animate-spin" />
          Report
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
