<script lang="ts" setup>
import { usePlayerStore } from '~/stores/usePlayerStore'

const playerStore = usePlayerStore()

const handlePlay = () => {
  playerStore.play()
}

const handlePause = () => {
  playerStore.pause()
}

const handleRewind = () => {
  playerStore.rewind()
}

const handleForward = () => {
  playerStore.forward()
}
</script>

<template>
  <div v-if="playerStore.media" class="relative bg-white grid grid-cols-3 gap-4">
    <div class="flex items-center gap-5 p-4">
      <div v-if="playerStore.media.logo" class="size-16 rounded-full">
        <NuxtLink :to="playerStore.media.route">
          <PlayerLogo />
        </NuxtLink>
      </div>
      <div class="flex-1 shrink-0 flex flex-col gap-1 justify-center overflow-hidden">
        <PlayerTitle />
        <NuxtLink :to="playerStore.media.route" class="text-sm font-medium truncate hyphens-auto">
          {{ playerStore.media.subTitle }}
        </NuxtLink>
      </div>
    </div>
    <div class="flex flex-col justify-between py-2">
      <div class="flex items-center space-x-2 justify-center h-full">
        <!--        <Button-->
        <!--          variant="outline"-->
        <!--          size="icon"-->
        <!--          class="rounded-full border-0 h-12 w-12 bg-gray-100 hover:bg-gray-200 active:bg-gray-300"-->
        <!--        >-->
        <!--          1x-->
        <!--        </Button>-->

        <Button
          v-if="playerStore.duration && !playerStore.preRoll && playerStore.isFile"
          aria-label="Rewind 15 seconds"
          class="rounded-full border-0 size-11 bg-gray-100 hover:bg-gray-200 active:bg-gray-300"
          size="icon"
          variant="outline"
          @click="handleRewind"
        >
          <Icon name="solar:rewind-15-seconds-back-linear" size="24" />
        </Button>

        <button v-if="!playerStore.preRoll && !playerStore.isPlaying" aria-label="Play" @click="handlePlay">
          <Icon name="solar:play-circle-bold" size="58" />
        </button>

        <button v-else-if="playerStore.isLoading" aria-label="Loading">
          <Icon class="animate-spin" name="mingcute:loading-3-fill" size="58" />
        </button>

        <button v-else-if="!playerStore.preRoll && playerStore.isPlaying" aria-label="Pause" @click="handlePause">
          <Icon name="solar:pause-circle-bold" size="58" />
        </button>

        <Button
          v-if="playerStore.duration && !playerStore.preRoll && playerStore.isFile"
          class="rounded-full border-0 h-12 w-12 bg-gray-100 hover:bg-gray-200 active:bg-gray-300"
          size="icon"
          variant="outline"
          @click="handleForward"
        >
          <Icon name="solar:rewind-15-seconds-forward-linear" size="24" />
        </Button>

        <div v-if="!!playerStore.preRoll" class="text-lg font-medium truncate hyphens-auto">
          The content will resume shortly
        </div>

        <!--        <Button-->
        <!--          variant="outline"-->
        <!--          size="icon"-->
        <!--          class="rounded-full border-0 h-12 w-12 bg-gray-100 hover:bg-gray-200 active:bg-gray-300"-->
        <!--        >-->
        <!--          <Icon size="24" name="solar:alarm-sleep-linear" />-->
        <!--        </Button>-->
        <!--        <Button-->
        <!--          variant="outline"-->
        <!--          size="icon"-->
        <!--          class="rounded-full border-0 h-14 w-14 bg-gray-100 hover:bg-gray-200 active:bg-gray-300"-->
        <!--        >-->
        <!--        </Button>-->
      </div>

      <PlayerProgress v-if="playerStore.duration && playerStore.isFile" />
    </div>
    <div class="flex items-center justify-end gap-3 p-4">
      <PlayerVolume />

      <PlayerReportDialog v-if="!playerStore.preRoll && (playerStore.isStation || playerStore.isEpisode)" />

      <!--      <Button-->
      <!--        variant="outline"-->
      <!--        size="icon"-->
      <!--        class="rounded-full border-0 h-12 w-12 bg-gray-100 hover:bg-gray-200 active:bg-gray-300"-->
      <!--      >-->
      <!--        <Icon size="24" name="solar:screencast-linear" />-->
      <!--      </Button>-->

      <!--      <Button-->
      <!--        variant="outline"-->
      <!--        size="icon"-->
      <!--        class="rounded-full border-0 h-12 w-12 bg-gray-100 hover:bg-gray-200 active:bg-gray-300"-->
      <!--      >-->
      <!--        <Icon size="24" name="solar:square-share-line-linear" />-->
      <!--      </Button>-->
    </div>
  </div>
</template>

<style scoped></style>
