<script setup lang="ts">
import PlayerVolumeSlider from '~/components/Player/PlayerVolumeSlider.vue'
import { usePlayerStore } from '~/stores/usePlayerStore'

const DEFAULT_VOLUME = 100

const playerStore = usePlayerStore()

const sliderValue = ref([playerStore.volume * 100])

const handleMute = () => {
  playerStore.volume = 0
  sliderValue.value = [0]
}

const handleUnmute = () => {
  playerStore.volume = DEFAULT_VOLUME / 100
  sliderValue.value = [DEFAULT_VOLUME]
}

watch(sliderValue, (newValue) => {
  playerStore.volume = newValue[0] / 100
})
</script>

<template>
  <div class="group h-11 flex items-center rounded-full pl-4 py-2 pr-6 bg-zeno-gray-light gap-2">
    <button v-if="!playerStore.volume" @click="handleUnmute">
      <Icon size="24" name="clarity:volume-mute-line" />
    </button>

    <!--    <Icon size="24" name="solar:volume-loud-linear" />-->
    <!--    <Icon size="24" name="solar:volume-cross-linear" />-->

    <button v-else @click="handleMute">
      <Icon size="24" name="clarity:volume-up-line" />
    </button>

    <PlayerVolumeSlider v-model="sliderValue" class="w-20" :default-value="[30]" :max="100" :min="0" :step="1" />
  </div>
</template>

<style scoped></style>
